import { lazy, Fragment } from "react";
import { Row, Col } from "antd";
import i18n from "i18next";
import { withTranslation } from "react-i18next";

import * as S from "./styles";

import logoImg from "../../assets/images/logo.png";
import telImg from "../../assets/images/ic-phone.png";
import instagramImg from "../../assets/images/ic-instagram.png";
import facebookImg from "../../assets/images/ic-facebook.png";

const Container = lazy(() => import("../../common/Container"));

const SocialLink = ({ href, src, style }) => {
  return (
    <S.SocialLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      key={src}
      aria-label={src}
      style={style}
    >
      <img src={src} width="25px" height="25px" />
    </S.SocialLink>
  );
};

export const SocialLinks = ({ style }) => (
  <>
    <SocialLink style={style} href="tel:+1 333 333 333" src={telImg} />
    <SocialLink style={style} href="https://facebook.com" src={facebookImg} />
    <SocialLink style={style} href="https://instagram.com" src={instagramImg} />
  </>
);

const Footer = ({ t }) => {
  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Fragment>
      <S.Footer>
        <Container>
          <Row type="flex" justify="space-between">
            <Col lg={8} md={8} sm={24} xs={24}>
              <S.Language>{t("Contact")}</S.Language>
              {/* <S.Large to="/">{t("Tell us everything")}</S.Large> */}
              <S.Para>
                {t(
                  `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
                )}
              </S.Para>
              <a href="https://wa.me/+1333333333?text=Hi! Lorem ipsum...">
                <S.Chat>{t(`Let's Chat`)}</S.Chat>
              </a>
            </Col>

            <Col lg={8} md={8} sm={24} xs={24}>
              <S.Language>{t("ADDRESS")}</S.Language>
              <S.Para>Rancho Santa Margarita</S.Para>
              <S.Para>2131 Elk Street</S.Para>
              <S.Para>California</S.Para>
            </Col>

            <Col lg={8} md={8} sm={24} xs={24}>
              <S.Select>
                <S.Language htmlFor="select-lang">{t("Language")}</S.Language>
                <S.LangSelect
                  onChange={handleChange}
                  value={i18n.language}
                  id="select-lang"
                >
                  <option value="en">English</option>
                  <option value="es">Español</option>
                </S.LangSelect>
              </S.Select>
            </Col>
          </Row>

          {/*
            <Row type="flex" justify="space-between">

              <Col lg={8} md={8} sm={12} xs={24}>
                <S.Title>{t("Company")}</S.Title>
                <S.Large left="true" to="/">
                  {t("About")}
                </S.Large>
                <S.Large left="true" to="/">
                  {t("Blog")}
                </S.Large>
                <S.Large left="true" to="/">
                  {t("Press")}
                </S.Large>
                <S.Large left="true" to="/">
                  {t("Careers & Culture")}
                </S.Large>
              </Col> 

              <Col lg={6} md={6} sm={12} xs={24}>
                <S.Empty />
                <S.Large left="true" to="/">
                  {t("Support Center")}
                </S.Large>
                <S.Large left="true" to="/">
                  {t("Customer Support")}
                </S.Large>
              </Col>

              <Col lg={8} md={8} sm={12} xs={24}>
                <S.Title>{t("Policy")}</S.Title>
                <S.Large to="/" left="true">
                  {t("Application Security")}
                </S.Large>
                <S.Large left="true" to="/">
                  {t("Software Principles")}
                </S.Large>
              </Col> 
            </Row>
            */}
        </Container>
      </S.Footer>
      <S.Extra>
        <Container border="true">
          <S.BottomRow
            type="flex"
            justify="flex-end"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <S.NavLink to="/">
              <S.LogoContainer>
                <img src={logoImg} aria-label="homepage" width="200px" />
              </S.LogoContainer>
            </S.NavLink>
            <S.FooterContainer>
              <SocialLinks style={{ marginLeft: 16 }} />
            </S.FooterContainer>
          </S.BottomRow>
        </Container>
      </S.Extra>
    </Fragment>
  );
};

export default withTranslation()(Footer);
