import { useState, Fragment, lazy } from "react";
import { Row, Col, Drawer } from "antd";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";

import * as S from "./styles";

import logoImg from "../../assets/images/logo.png";

const Button = lazy(() => import("../../common/Button"));

export const scrollTo = (id) => {
  const yOffset = -56;
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({
    top: y,
    behavior: "smooth",
  });
};

const Header = ({ t }) => {
  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <Fragment>
        <S.CustomNavLinkSmall
          onClick={() => {
            scrollTo("dj");
            setVisibility(false);
          }}
        >
          <S.Span>{t("DJ Set")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall
          onClick={() => {
            scrollTo("band");
            setVisibility(false);
          }}
        >
          <S.Span>{t("Band")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall
          onClick={() => {
            scrollTo("events");
            setVisibility(false);
          }}
        >
          <S.Span>{t("Events")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => {
            scrollTo("contact");
            setVisibility(false);
          }}
        >
          <S.Span>
            <Button color="#ffffff">{t("Contact")}</Button>
          </S.Span>
        </S.CustomNavLinkSmall>
      </Fragment>
    );
  };

  return (
    <S.Header>
      <S.Container>
        <Row type="flex" justify="space-between" align="middle" gutter={20}>
          <S.LogoContainer to="/" aria-label="homepage">
            <img
              src={logoImg}
              width={122}
              style={{ filter: "brightness(0) invert(100%)" }}
            />
          </S.LogoContainer>
          <S.NotHidden>
            <MenuItem />
          </S.NotHidden>
          <S.Burger onClick={showDrawer}>
            <S.Outline />
          </S.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <S.Label onClick={onClose}>
                <Col span={12}>
                  <S.Menu>Menu</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline padding="true" />
                </Col>
              </S.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);
